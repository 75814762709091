import {
  CONSTANTS_COMPANY,
  HOME_ROUTE_NAME,
  REACTIVATE_ACCOUNT_AGENT_ROUTE_NAME,
  REACTIVATE_ACCOUNT_ROUTE_NAME,
  SIGNUP_COMPLETE_AGENT_ROUTE_NAME,
  SIGNUP_COMPLETE_ROUTE_NAME
} from '@/global/globalVariables';

const { name: companyName } = CONSTANTS_COMPANY;

export const BROKER_ROUTES = [
  // broker/agents
  {
    path: 'agents',
    name: 'BrokerAgentsView',
    component: () => import('@/views/protected/broker/agents/BrokerAgentsView.vue'),
    meta: {
      title: `Agents`
    },
    redirect: { name: 'BrokerAgentsListView' }, // when accessing /agents, redirect to /agents/list
    children: [
      // broker/agets/list
      {
        path: 'list',
        name: 'BrokerAgentsListView',
        component: () => import('@/views/protected/broker/agents/agents-list/BrokerAgentsListView.vue'),
        meta: {
          title: `Agents List`
        }
      },
      // broker/agents/invites
      {
        path: 'invites',
        name: 'BrokerAgentsInvitesView',
        component: () => import('@/views/protected/broker/agents/agents-invites/BrokerAgentsInvitesView.vue'),
        meta: {
          title: `Agent Invites`
        }
      }
    ]
  },
  // broker/office-settings
  {
    path: 'office-settings',
    name: 'BrokerOfficeSettingsView',
    component: () => import('@/views/protected/broker/office-settings/BrokerOfficeSettingsView.vue'),
    meta: {
      title: `Office Settings`
    },
    redirect: { name: 'BrokerOfficeSettingsDetailsView' }, // when accessing /office-settings, redirect to /office-settings/details
    children: [
      // broker/office-settings/details
      {
        path: 'details',
        name: 'BrokerOfficeSettingsDetailsView',
        component: () => import('@/views/protected/broker/office-settings/office-settings-details/BrokerOfficeSettingsDetailsView.vue')
      },
      // broker/office-settings/appearance
      {
        path: 'appearance',
        name: 'BrokerOfficeSettingsAppearanceView',
        component: () =>
          import('@/views/protected/broker/office-settings/office-settings-appearance/BrokerOfficeSettingsAppearanceView.vue')
      },
      // broker/office-settings/recruiting
      {
        path: 'recruiting',
        name: 'BrokerOfficeSettingsRecruitingView',
        component: () =>
          import('@/views/protected/broker/office-settings/office-settings-recruiting/BrokerOfficeSettingsRecruitingView.vue')
      }
    ]
  }
];

// Private routes
export const PRIVATE_ROUTES_DASHBOARD = [
  // BROKER ROUTES
  {
    path: '/broker',
    name: 'BrokerLayout',
    redirect: { name: HOME_ROUTE_NAME },
    children: BROKER_ROUTES,
    // permission
    meta: {
      permission: 'broker'
    }
  },

  // AGENT ROUTES
  // get started
  {
    path: '/get-started',
    name: HOME_ROUTE_NAME,
    component: () => import('@/views/protected/GetStartedView.vue'),
    meta: {
      title: `Get Started`
    }
  },
  //  profile
  {
    path: '/profile',
    name: 'ProfileView',
    component: () => import('@/views/protected/profile/ProfileView.vue'),
    meta: {
      title: `Profile`
    },
    redirect: { name: 'ProfileGeneralView' }, // when accessing /profile, redirect to /profile/general
    children: [
      {
        path: 'general',
        name: 'ProfileGeneralView',
        component: () => import('@/views/protected/profile/general/ProfileGeneralView.vue')
      },
      {
        path: 'security',
        name: 'ProfileSecurityView',
        component: () => import('@/views/protected/profile/security/ProfileSecurityView.vue')
      },
      {
        path: 'billing',
        name: 'ProfileBillingView',
        component: () => import('@/views/protected/profile/billing/ProfileBillingView.vue')
      },
      {
        path: 'notifications',
        name: 'ProfileNotificationsView',
        component: () => import('@/views/protected/profile/notifications/ProfileNotificationsView.vue')
      }
    ]
  },
  // account settings
  {
    path: '/account',
    name: 'AccountView',
    component: () => import('@/views/protected/account/AccountView.vue'),
    meta: {
      title: `Account Settings`
    },
    redirect: { name: 'AccountPreferencesView' }, // when accessing /account, redirect to /account/preferences
    children: [
      {
        path: 'preferences',
        name: 'AccountPreferencesView',
        component: () => import('@/views/protected/account/preferences/AccountPreferencesView.vue')
      },
      {
        path: 'appearance',
        name: 'AccountAppearanceView',
        component: () => import('@/views/protected/account/appearance/AccountAppearanceView.vue')
      },
      {
        path: 'domains',
        name: 'AccountDomainsView',
        component: () => import('@/views/protected/account/domains/AccountDomainsView.vue')
      },
      {
        path: 'custom-code',
        name: 'AccountCustomCodeView',
        component: () => import('@/views/protected/account/custom-code/AccountCustomCodeView.vue')
      },
      {
        path: 'email-signature',
        name: 'AccountEmailSignatureView',
        component: () => import('@/views/protected/account/email-signature/AccountEmailSignatureView.vue')
      },
      {
        path: 'lead-links',
        name: 'AccountLeadLinksView',
        component: () => import('@/views/protected/account/lead-links/AccountLeadLinksView.vue')
      },
      {
        path: 'sections',
        name: 'AccountSectionsView',
        component: () => import('@/views/protected/account/sections/AccountSectionsView.vue')
      }
    ]
  },
  // leads
  {
    path: '/leads',
    name: 'LeadsView',
    component: () => import('@/views/protected/leads/LeadsView.vue'),
    meta: {
      title: `Leads`
    }
  },
  // lead details
  {
    path: '/leads/:leadId',
    props: true, // pass the route params to the component
    name: 'LeadDetailsView',
    component: () => import('@/views/protected/lead-details/LeadDetailsView.vue'),
    meta: {
      title: `Lead Details`
    }
  },
  // ads views
  {
    path: '/ads',
    name: 'AdsView',
    component: () => import('@/views/protected/ads/ads-view/AdsView.vue'),
    meta: {
      title: `Ads`
    },
    redirect: { name: 'AdsCampaignsView' }, // when accessing /ads, redirect to /ads/campaigns
    children: [
      {
        path: 'campaigns',
        name: 'AdsCampaignsView',
        component: () => import('@/views/protected/ads/ads-view/ads-campaigns/AdsCampaignsView.vue'),
        meta: {
          title: `Ads Campaigns`
        }
      },
      {
        path: 'audiences',
        name: 'AdsAudiencesView',
        component: () => import('@/views/protected/ads/ads-view/ads-audiences/AdsAudiencesView.vue'),
        meta: {
          title: `Ads Audiences`
        }
      },
      {
        path: 'settings',
        name: 'AdsSettingsView',
        component: () => import('@/views/protected/ads/ads-view/ads-settings/AdsSettingsView.vue'),
        meta: {
          title: `Ads Settings`
        }
      }
    ]
  },

  /*
  NOTE: THE ROUTE ads/create/:adContentType BELONGS TO FACEBOOK ADS
        OTHER AD TYPES LIKE GOOGLE SHOULD BE ADDED LIKE 
  {
    path: 'ads/google/create/:adContentType'
    component: () => import('@/views/protected/ads/ads-google-create/AdsGoogleCreateView.vue'),
  }
  */
  // ads/create/:adContentType
  {
    path: '/ads/create/:adContentType', // adContentType: listing,landing_page,pdf_guide,custom
    name: 'AdsCreateView',
    props: true, // pass the route params to the component
    component: () => import('@/views/protected/ads/ads-create/AdsCreateView.vue'),
    meta: {
      title: `Create Ad`
    }
  },

  // listings views
  {
    path: '/listings',
    name: 'ListingsView',
    component: () => import('@/views/protected/listings/listings-view/ListingsView.vue'),
    meta: {
      title: `Listings`
    },
    redirect: { name: 'ListingsTableAndGridView' }, // when accessing /listings, redirect to /listings/all
    children: [
      {
        path: 'all',
        name: 'ListingsTableAndGridView',
        component: () => import('@/views/protected/listings/listings-view/listings-table-and-grid/ListingsTableAndGridView.vue')
      },
      {
        path: 'presentation',
        name: 'ListingsPresentationView',
        component: () => import('@/views/protected/listings/listings-view/listings-presentation/ListingsPresentationView.vue'),
        meta: {
          title: `Listings Presentation`
        }
      },
      {
        path: 'tools',
        name: 'ListingsToolsView',
        component: () => import('@/views/protected/listings/listings-view/listings-tools/ListingsToolsView.vue'),
        meta: {
          title: `Listings Tools`
        }
      },
      {
        path: 'settings',
        name: 'ListingsSettingsView',
        component: () => import('@/views/protected/listings/listings-view/listings-settings/ListingsSettingsView.vue'),
        meta: {
          title: `Listings Settings`
        }
      }
    ]
  },
  /*
    listing/new 
  */
  {
    // TODO: THIS IS THE OLD WORKFLOW, THIS SHOULD BE REMOVED
    // AND REPLACED WITH listing/create
    path: '/listings/new',
    name: 'listingAddView',
    redirect: { name: 'listingCreateView' },
    meta: {
      title: `Add New Listing`
    }
  },
  /*
    listing/new/:listingId
  */
  {
    // TODO: THIS IS THE OLD WORKFLOW, THIS SHOULD BE REMOVED
    // AND REPLACED WITH listing/create/:listingUuid
    path: '/listings/new/:listingId',
    props: true, // pass the route params to the component
    name: 'ListingAddDynamicView',
    redirect: { name: 'ListingPublishView' },
    meta: {
      title: `Publish Listing`
    }
  },

  /*
    listing/create 
  */
  {
    path: '/listings/create',
    name: 'listingCreateView',
    component: () => import('@/views/protected/listings/listing-create/ListingCreateView.vue'),
    meta: {
      title: `Create Listing`
    }
  },
  /*
    listing/create/:listingUuid
  */
  {
    path: '/listings/create/:listingUuid',
    props: true, // pass the route params to the component
    name: 'ListingPublishView',
    component: () => import('@/views/protected/listings/listing-create/ListingCreateView.vue'),
    meta: {
      title: `Publish Listing`
    }
  },

  // listings/edit/:listingId
  {
    path: '/listings/edit/:listingId',
    props: true, // pass the route params to the component
    component: () => import('@/views/protected/listings/listing-edit/ListingEditView.vue'),
    redirect: { name: 'ListingEditMarketView' },
    meta: {
      title: `Edit Listing`
    },
    children: [
      {
        /* /listing/:listingId/edit/details */
        path: 'details',
        name: 'ListingEditDetailsView',
        component: () => import('@/views/protected/listings/listing-edit/listing-edit-details/ListingEditDetailsView.vue')
      },
      {
        /* /listing/:listingId/edit/market */
        path: 'market',
        name: 'ListingEditMarketView',
        component: () => import('@/views/protected/listings/listing-edit/listing-edit-market/ListingEditMarketView.vue'),
        meta: {
          title: `Market Listing`
        }
      },
      {
        /* /listing/:listingId/edit/statistics */
        path: 'statistics',
        name: 'ListingEditStatisticsView',
        component: () => import('@/views/protected/listings/listing-edit/listing-edit-statistics/ListingEditStatisticsView.vue'),
        meta: {
          title: `Listing's Statistics`
        }
      }
    ]
  },

  /*
    bots 
  */
  {
    path: '/bots',
    name: 'BotsView',
    component: () => import('@/views/protected/bots/BotsView.vue'),
    meta: {
      title: `Bots`
    }
  },
  // bots/edit/:botId
  {
    path: '/bots/edit/:botId',
    props: true, // pass the route params to the component
    name: 'BotEditView',
    component: () => import('@/views/protected/bots/bot-edit/BotEditView.vue'),
    meta: {
      title: `Edit Bot`
    },
    redirect: { name: 'BotEditSubscribersView' },
    children: [
      {
        /* /bots/edit/:botId/subscribers */
        path: 'subscribers',
        name: 'BotEditSubscribersView',
        component: () => import('@/views/protected/bots/bot-edit/bot-edit-subscribers/BotEditSubscribersView.vue')
      },
      {
        /* /bots/edit/:botId/settings */
        path: 'settings',
        name: 'BotEditSettingsView',
        component: () => import('@/views/protected/bots/bot-edit/bot-edit-settings/BotEditSettingsView.vue')
      },
      {
        /* /bots/edit/:botId/statistics */
        path: 'statistics',
        name: 'BotEditStatisticsView',
        component: () => import('@/views/protected/bots/bot-edit/bot-edit-statistics/BotEditStatisticsView.vue')
      }
    ]
  },

  // follow ups
  {
    path: '/follow-ups',
    name: 'FollowUpsView',
    component: () => import('@/views/protected/follow-ups/FollowUpsView.vue'),
    meta: {
      title: `Follow Ups`
    },
    redirect: { name: 'FollowUpsCampaignView' },
    children: [
      {
        path: 'campaigns',
        name: 'FollowUpsCampaignView',
        component: () => import('@/views/protected/follow-ups/follow-ups-campaigns/FollowUpsCampaignView.vue')
      },
      {
        path: 'email-blast',
        name: 'FollowUpsEmailBlastView',
        component: () => import('@/views/protected/follow-ups/follow-ups-email-blast/FollowUpsEmailBlastView.vue')
      },
      {
        path: 'contact-lists',
        name: 'FollowUpsContactListsView',
        component: () => import('@/views/protected/follow-ups/follow-ups-contact-lists/FollowUpsContactListsView.vue')
      },
      {
        path: 'settings',
        name: 'FollowUpsSettingsView',
        component: () => import('@/views/protected/follow-ups/follow-ups-settings/FollowUpsSettingsView.vue')
      }
    ]
  },
  {
    path: '/follow-ups/campaigns/:campaignId',
    name: 'FollowUpsCampaignEditView',
    component: () => import('@/views/protected/follow-ups/follow-ups-campaigns/campaign-edit/FollowUpsCampaignEditView.vue'),
    meta: {
      title: `Edit Campaign`
    },
    redirect: { name: 'FollowUpsCampaignEditStepsView' },
    children: [
      {
        path: 'steps',
        name: 'FollowUpsCampaignEditStepsView',
        component: () =>
          import('@/views/protected/follow-ups/follow-ups-campaigns/campaign-edit/campaign-edit-steps/FollowUpsCampaignEditStepsView.vue')
      },
      {
        path: 'stats',
        name: 'FollowUpsCampaignEditStatsView',
        component: () =>
          import('@/views/protected/follow-ups/follow-ups-campaigns/campaign-edit/campaign-edit-stats/FollowUpsCampaignEditStatsView.vue')
      },
      {
        path: 'settings',
        name: 'FollowUpsCampaignEditSettingsView',
        component: () =>
          import(
            '@/views/protected/follow-ups/follow-ups-campaigns/campaign-edit/campaign-edit-settings/FollowUpsCampaignEditSettingsView.vue'
          )
      }
    ]
  },
  {
    path: '/follow-ups/email-blast/:emailBlastId/stats',
    name: 'FollowUpsEmailBlastStatsView',
    component: () => import('@/views/protected/follow-ups/follow-ups-email-blast/email-blast-stats/FollowUpsEmailBlastStatsView.vue'),
    redirect: { name: 'FollowUpsEmailBlastStatsEmail' },
    children: [
      {
        path: 'email',
        name: 'FollowUpsEmailBlastStatsEmail',
        component: () =>
          import(
            '@/views/protected/follow-ups/follow-ups-email-blast/email-blast-stats/email-blast-stats-email/FollowUpsEmailBlastStatsEmail.vue'
          )
      },
      {
        path: 'recipients',
        name: 'FollowUpsEmailBlastStatsRecipients',
        component: () =>
          import(
            '@/views/protected/follow-ups/follow-ups-email-blast/email-blast-stats/email-blast-stats-recipients/FollowUpsEmailBlastStatsRecipients.vue'
          )
      }
    ]
  },
  {
    path: '/follow-ups/email-blast/:emailBlastId/edit',
    name: 'FollowUpsEmailBlastEditView',
    component: () => import('@/views/protected/follow-ups/follow-ups-email-blast/email-blast-edit/FollowUpsEmailBlastEditView.vue')
  },
  {
    path: '/follow-ups/contact-lists/:contactListId/contacts',
    name: 'FollowUpsContactListContacts',
    component: () => import('@/views/protected/follow-ups/follow-ups-contact-lists/FollowUpsContactListsContactsView.vue')
  },
  // New Media Library
  {
    path: '/media-library',
    name: 'MediaLibraryView',
    component: () => import('@/views/protected/media-library/MediaLibraryView.vue'),
    meta: {
      title: `Media Library`
    },
    redirect: { name: 'MediaLibraryTableAndGridView' },
    children: [
      {
        path: 'files',
        name: 'MediaLibraryTableAndGridView',
        component: () => import('@/views/protected/media-library/media-library-table-and-grid/MediaLibraryTableAndGridView.vue')
      },
      {
        path: 'l2l-media',
        name: 'MediaLibraryL2LMediaView',
        component: () => import('@/views/protected/media-library/media-library-l2l-media/MediaLibraryL2LMediaView.vue')
      },
      {
        path: 'stock-images',
        name: 'MediaLibraryStockImagesView',
        component: () => import('@/views/protected/media-library/media-library-stock-images/MediaLibraryStockImagesView.vue')
      },
      {
        path: 'stock-videos',
        name: 'MediaLibraryStockVideosView',
        component: () => import('@/views/protected/media-library/media-library-stock-videos/MediaLibraryStockVideosView.vue')
      }
    ]
  },
  // NEW LANDING PAGE ROUTES
  {
    path: '/pages',
    name: 'LandingPagesView',
    component: () => import('@/views/protected/landing-pages/LandingPagesView.vue'),
    meta: {
      title: `Landing Pages`
    },
    redirect: { name: 'LandingPagesTableAndGridView' }, // when accessing /listings, redirect to /pages/all
    children: [
      {
        path: 'all',
        name: 'LandingPagesTableAndGridView',
        component: () => import('@/views/protected/landing-pages/landing-pages-table-and-grid/LandingPagesTableAndGridView.vue')
      },
      {
        path: 'settings',
        name: 'LandingsPagesSettingsView',
        component: () => import('@/views/protected/landing-pages/landing-pages-settings/LandingPagesSettingsView.vue')
      }
    ]
  },
  // page/:pageUuid
  {
    path: '/pages/:pageUuid',
    props: true, // pass the route params to the component
    component: () => import('@/views/protected/landing-pages/landing-pages-edit/LandingPagesEditView.vue'),
    name: 'LandingPagesEditView',
    redirect: { name: 'LandingPagesEditMarketView' },
    meta: {
      title: `Landing Page`
    },
    children: [
      {
        /* /pages/:pageUuid/market */
        path: 'market',
        name: 'LandingPagesEditMarketView',
        component: () =>
          import('@/views/protected/landing-pages/landing-pages-edit/landing-pages-edit-market/LandingPagesEditMarketView.vue'),
        meta: {
          title: `Market Landing Page`
        }
      },
      {
        /* /pages/:pageUuid/details */
        path: 'details',
        name: 'LandingPagesEditDetailsView',
        component: () =>
          import('@/views/protected/landing-pages/landing-pages-edit/landing-pages-edit-details/LandingPagesEditDetailsView.vue'),
        meta: {
          title: `Landing Page Details`
        }
      },
      {
        /* /pages/:pageUuid/statistics */
        path: 'statistics',
        name: 'LandingPagesEditStatisticsView',
        component: () =>
          import('@/views/protected/landing-pages/landing-pages-edit/landing-pages-edit-statistics/LandingPagesEditStatisticsView.vue'),
        meta: {
          title: `Landing Page's Statistics`
        }
      }
    ]
  },

  // social
  {
    path: '/social',
    name: 'SocialView',
    component: () => import('@/views/protected/social/SocialView.vue'),
    meta: {
      title: `Social`
    },
    redirect: { name: 'SocialCalendarView' }, // when accessing /listings, redirect to /listings/all
    children: [
      {
        path: 'calendar',
        name: 'SocialCalendarView',
        component: () => import('@/views/protected/social/social-calendar/SocialCalendarView.vue'),
        meta: {
          title: `Social Calendar`
        }
      },
      {
        path: 'topics',
        name: 'SocialTopicsView',
        component: () => import('@/views/protected/social/social-topics/SocialTopicsView.vue'),
        meta: {
          title: `Social Topics`
        }
      },
      {
        path: 'settings',
        name: 'SocialSettingsView',
        props: true, // pass the route params to the component
        component: () => import('@/views/protected/social/social-settings/SocialSettingsView.vue'),
        meta: {
          title: `Social Settings`
        }
      },
      {
        path: 'bio',
        name: 'SocialLinkInBioView',
        component: () => import('@/views/protected/social/social-link-in-bio/SocialLinkInBioView.vue'),
        meta: {
          title: `Link in Bio | Social`
        }
      },
      {
        path: 'history',
        name: 'SocialHistoryView',
        component: () => import('@/views/protected/social/social-history/SocialHistoryView.vue'),
        meta: {
          title: `Social History`
        }
      }
    ]
  },
  // social topics posts
  {
    path: '/social/topics/edit/:topicId/:topicType',
    props: true, // pass the route params to the component
    name: 'SocialTopicsEditView',
    component: () => import('@/views/protected/social/social-topics/social-topics-edit/SocialTopicsEditView.vue'),
    meta: {
      title: 'Social Topic Edit'
    }
  },
  // social accounts
  {
    path: '/social/add-accounts',
    name: 'SocialAddAccountsView',
    component: () => import('@/views/protected/social/social-add-accounts/SocialAddAccountsView.vue'),
    meta: {
      title: 'Add Social Accounts'
    }
  },
  // reviews
  {
    name: 'ReviewsView',
    path: '/reviews',
    component: () => import('@/views/protected/reviews/ReviewsView.vue'),
    meta: {
      title: 'Reviews'
    },
    redirect: { name: 'ReviewsMarketingView' }, // when accessing /reviews, redirect to /reviews/market
    children: [
      {
        name: 'ReviewsMarketingView',
        path: 'market',
        component: () => import('@/views/protected/reviews/review-marketing/ReviewsMarketingView.vue')
      },
      {
        name: 'ReviewsWebsiteView',
        path: 'website',
        component: () => import('@/views/protected/reviews/reviews-website/ReviewsWebsiteView.vue')
      },
      {
        name: 'ReviewsTableView',
        path: 'table',
        component: () => import('@/views/protected/reviews/reviews-table/ReviewsTableView.vue')
      },
      {
        name: 'ReviewsSettingsView',
        path: 'settings',
        component: () => import('@/views/protected/reviews/reviews-settings/ReviewsSettingsView.vue')
      }
    ]
  },

  // text-ivr
  {
    path: '/text-ivr',
    name: 'TextIvrView',
    component: () => import('@/views/protected/text-ivr/TextIvrView.vue'),
    meta: {
      title: `Text / IVR`
    }
  },

  // pdf guides
  {
    path: '/guides',
    name: 'PdfGuidesView',
    component: () => import('@/views/protected/pdf-guides/PdfGuidesView.vue'),
    meta: {
      title: `PDF Guides`
    },
    redirect: { name: 'PdfGuidesTableAndGridView' }, // when accessing /guides, redirect to /guides/all
    children: [
      {
        path: 'all',
        name: 'PdfGuidesTableAndGridView',
        component: () => import('@/views/protected/pdf-guides/pdf-guides-table-and-grid/PdfGuidesTableAndGridView.vue')
      },
      {
        path: 'settings',
        name: 'PdfGuidesSettingsView',
        component: () => import('@/views/protected/pdf-guides/pdf-guides-settings/PdfGuidesSettingsView.vue'),
        meta: {
          title: 'PDF Guides Settings'
        }
      }
    ]
  },
  // guides/edit/:guideId
  {
    path: '/guides/:guideId',
    props: true, // pass the route params to the component
    component: () => import('@/views/protected/pdf-guides/pdf-guides-edit/PdfGuidesEditView.vue'),
    name: 'PdfGuidesEditView',
    redirect: { name: 'PdfGuideEditMarketView' },
    meta: {
      title: `PDF Guide`
    },
    children: [
      {
        /* /guides/edit/:guideId/market */
        path: 'market',
        name: 'PdfGuideEditMarketView',
        component: () => import('@/views/protected/pdf-guides/pdf-guides-edit/pdf-guides-edit-market/PdfGuidesEditMarketView.vue'),
        meta: {
          title: `Market PDF Guide`
        }
      },
      {
        /* /guides/edit/:guideId/details */
        path: 'details',
        name: 'PdfGuideEditDetailsView',
        component: () => import('@/views/protected/pdf-guides/pdf-guides-edit/pdf-guides-edit-details/PdfGuidesEditDetailsView.vue'),
        meta: {
          title: `PDF Guide Details`
        }
      },
      {
        /* /guides/edit/:guideId/statistics */
        path: 'statistics',
        name: 'PdfGuideEditStatisticsView',
        component: () => import('@/views/protected/pdf-guides/pdf-guides-edit/pdf-guides-edit-statistics/PdfGuidesEditStatisticsView.vue'),
        meta: {
          title: `PDF Guide's Statistics`
        }
      }
    ]
  }
];

// these are private routes that are dont need the PRIVATE_ROUTES layout
export const PRIVATE_ROUTES_STANDALONE = [
  // landing pages editor
  {
    path: '/pages/editor/:pageUuid/:pageType',
    props: true, // pass the route params to the component
    component: () => import('@/views/protected/landing-pages/landing-pages-editor/LandingPagesEditorView.vue'),
    name: 'LandingPagesEditorView',
    meta: {
      title: `Landing Page Editor`
    }
  },

  {
    // reactivate account
    path: '/reactivate',
    name: REACTIVATE_ACCOUNT_ROUTE_NAME,
    component: () => import('@/views/protected/reactivate-account/ReactivateAccountView.vue'),
    children: [
      {
        // agent reactivation page
        path: 'agent',
        name: REACTIVATE_ACCOUNT_AGENT_ROUTE_NAME,
        component: () => import('@/views/protected/reactivate-account/ReactivateAccountAgentView.vue'),
        meta: {
          title: `Reactivate Account`
        }
      }
    ]
  },

  {
    // complete sign up
    path: '/signup-complete',
    name: SIGNUP_COMPLETE_ROUTE_NAME,
    component: () => import('@/views/protected/signup-complete/SignupCompleteView.vue'),
    children: [
      {
        // agent complete sign up
        path: 'agent',
        name: SIGNUP_COMPLETE_AGENT_ROUTE_NAME,
        component: () => import('@/views/protected/signup-complete/SignupCompleteAgentView.vue'),
        meta: {
          title: `Complete Sign Up`
        }
      }
    ]
  },

  // callbacks
  {
    path: '/facebook/page/callback',
    name: 'FacebookPageCallbackView',
    component: () => import('@/views/protected/callbacks/FacebookPageCallbackView.vue')
  },
  // {
  //   path: '/facebook/group/callback',
  //   name: 'FacebookGroupCallbackView',
  //   component: () => import('@/views/protected/callbacks/FacebookGroupCallbackView.vue')
  // },
  {
    path: '/twitter/callback',
    name: 'TwitterAccountCallbackView',
    component: () => import('@/views/protected/callbacks/TwitterAccountCallbackView.vue')
  },
  {
    path: '/instagram/callback',
    name: 'InstagramBusinessCallbackView',
    component: () => import('@/views/protected/callbacks/InstagramBusinessCallbackView.vue')
  },
  {
    path: '/linkedin/page/callback',
    name: 'LinkedinPageCallbackView',
    component: () => import('@/views/protected/callbacks/LinkedinPageCallbackView.vue')
  },
  {
    path: '/linkedin/account/callback',
    name: 'LinkedinAccountCallbackView',
    component: () => import('@/views/protected/callbacks/LinkedinAccountCallbackView.vue')
  },
  {
    path: '/youtube/callback',
    name: 'YoutubeChannelCallbackView',
    component: () => import('@/views/protected/callbacks/YoutubeChannelCallbackView.vue')
  },
  {
    path: '/tiktok/callback',
    name: 'TiktokAccountCallbackView',
    component: () => import('@/views/protected/callbacks/TiktokAccountCallbackView.vue')
  },
  {
    path: '/pinterest/callback',
    name: 'PinterestAccountCallbackView',
    component: () => import('@/views/protected/callbacks/PinterestAccountCallbackView.vue')
  },

  // Bot callbacks
  {
    path: '/bot/chat/connect/callback',
    name: 'BotCallbackView',
    component: () => import('@/views/protected/callbacks/BotCallbackView.vue')
  },

  // Auth callbacks
  {
    // used for connecting facebook account
    path: '/facebook/callback/assign',
    name: 'FacebookAssignCallbackView',
    component: () => import('@/views/protected/callbacks/FacebookAssignCallbackView.vue')
  },

  {
    path: '/google/callback/assign',
    name: 'GoogleAssignCallbackView',
    component: () => import('@/views/protected/callbacks/GoogleAssignCallbackView.vue')
  },

  // Ads callbacks
  {
    // used for connecting facebook ads account
    path: '/marketing/callback/facebook',
    name: 'MarketingFacebookCallbackView',
    component: () => import('@/views/protected/callbacks/AdsFacebookCallbackView.vue')
  }
];

// combining the private routes
export const PRIVATE_ROUTES = [...PRIVATE_ROUTES_DASHBOARD, ...PRIVATE_ROUTES_STANDALONE];

// Public routes that dont need the PUBLIC_ROUTES layout
export const PUBLIC_ROUTES_STANDALONE = [
  // new route to validate token
  {
    name: 'AuthView',
    path: '/auth',
    component: () => import('@/views/protected/AuthView.vue')
  },
  {
    // used for login facebook account
    path: '/facebook/callback/signin',
    name: 'FacebookSigninCallbackView',
    component: () => import('@/views/protected/callbacks/FacebookSigninCallbackView.vue')
  },
  {
    // used for login google account
    path: '/google/callback/signin',
    name: 'GoogleSigninCallbackView',
    component: () => import('@/views/protected/callbacks/GoogleSigninCallbackView.vue')
  }
];

// Auth routes
export const AUTH_ROUTES = [
  {
    path: '/signup',
    name: 'SignupView',
    component: () => import('@/views/public/SignupView.vue'),
    meta: {
      title: `Sign Up`
    }
  },
  {
    path: '/login',
    name: 'LoginView',
    component: () => import('@/views/public/LoginView.vue'),
    meta: {
      title: `Login`
    }
  },
  {
    path: '/password-reset',
    name: 'PasswordResetView',
    component: () => import('@/views/public/PasswordResetView.vue'),
    meta: {
      title: `Password Reset`
    }
  }
];

export const PUBLIC_ROUTES = [
  {
    path: '/',
    name: 'HomeView',
    component: () => import('@/views/public/HomeView.vue'),
    meta: {
      title: `${companyName} | Get Buyers and Sellers with or without Listings`
    }
  },
  {
    path: '/about',
    name: 'AboutView',
    component: () => import('@/views/public/AboutView.vue'),
    meta: {
      title: `About Us`
    }
  },
  {
    path: '/unsubscribe',
    name: 'UnsubscribeView',
    component: () => import('@/views/public/UnsubscribeView.vue'),
    meta: {
      title: `Unsubscribe`
    }
  }
];

export const EXCEPTION_ROUTES = [
  {
    path: '/:pathMatch(.*)*',
    name: '404View',
    component: () => import('@/views/exceptions/404View.vue'),
    meta: {
      title: 'Not Found'
    }
  },
  // 401
  {
    path: '/401',
    name: '401View',
    component: () => import('@/views/exceptions/401View.vue'),
    meta: {
      title: 'Unauthorized'
    }
  },
  // 403
  {
    path: '/403',
    name: '403View',
    component: () => import('@/views/exceptions/403View.vue'),
    meta: {
      title: 'Forbidden'
    }
  },
  // 500
  {
    path: '/500',
    name: '500View',
    component: () => import('@/views/exceptions/500View.vue'),
    meta: {
      title: 'Internal Server Error'
    }
  }
];

export const ROUTES = [
  // Private
  {
    path: '/dashboard',
    name: 'PrivateLayout',
    meta: {
      isAuth: true
    },
    component: () => import('@/layouts/PrivateLayout.vue'),
    children: PRIVATE_ROUTES_DASHBOARD
  },
  // Standalone: private routes that dont extend the PRIVATE_ROUTES layout
  {
    path: '/dashboard',
    name: 'PrivateStandaloneLayout',
    meta: {
      isAuth: true
    },
    children: PRIVATE_ROUTES_STANDALONE
  },
  // Public
  {
    path: '/',
    name: 'PublicLayout',
    component: () => import('@/layouts/PublicLayout.vue'),
    children: [...PUBLIC_ROUTES, ...AUTH_ROUTES]
  },
  // Standalone: public routes that dont extend the PUBLIC_ROUTES layout
  {
    path: '/',
    name: 'PublicStandaloneLayout',
    children: PUBLIC_ROUTES_STANDALONE
  },
  // Exceptions
  {
    path: '/exceptions',
    name: 'ExceptionsLayout',
    component: () => import('@/layouts/ExceptionsLayout.vue'),
    children: EXCEPTION_ROUTES
  }
];
