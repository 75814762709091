import router from '..';
import { PRIVATE_ROUTES } from '../routes';
import { useAuthStore } from '@/stores/auth';
import { flattenRoutes } from '@/helpers/convert';
import { usePaymentsStore } from '@/stores/payments';
import {
  REACTIVATE_ACCOUNT_AGENT_ROUTE_NAME,
  REACTIVATE_ACCOUNT_ROUTE_NAME,
  SIGNUP_COMPLETE_AGENT_ROUTE_NAME,
  SIGNUP_COMPLETE_ROUTE_NAME
} from '@/global/globalVariables';
import { useNewProfileStore } from '@/stores/profile';

const usePaymentGuard = () => {
  const userStore = useAuthStore();
  const paymentsStore = usePaymentsStore();
  const newProfileStore = useNewProfileStore();

  // Flatten the PRIVATE_ROUTES array to include all nested routes
  const flattenedPrivateRoutes = flattenRoutes(PRIVATE_ROUTES);

  router.beforeEach((to, from, next) => {
    const isAuthenticated = !!userStore.getToken;
    const isPrivateRoute = flattenedPrivateRoutes.some(route => route.name === to.name);
    const isPlanStatusInactive = paymentsStore.isPlanStatusInactive;
    const isUserOnboarding = newProfileStore.getIsUserOnboarding;
    const isUserRecovering = newProfileStore.getIsUserRecovering;

    // if the user is authenticated and the route is private e
    if (isAuthenticated && isPrivateRoute) {
      if (isUserOnboarding) {
        // user is still onboarding (didn't complete the sign up process)
        if (to.name !== SIGNUP_COMPLETE_ROUTE_NAME && to.name !== SIGNUP_COMPLETE_AGENT_ROUTE_NAME) {
          // Redirect to complete signup page if we are not already on one
          return next({ name: SIGNUP_COMPLETE_ROUTE_NAME });
        }
      } else if (isPlanStatusInactive || isUserRecovering) {
        // user's plan status is inactive, or user is recovering their deleted account
        if (to.name !== REACTIVATE_ACCOUNT_ROUTE_NAME && to.name !== REACTIVATE_ACCOUNT_AGENT_ROUTE_NAME) {
          // Redirect to reactivation page if we are not already on one
          return next({ name: REACTIVATE_ACCOUNT_ROUTE_NAME });
        }
      }
    }

    next();
  });
};

export default usePaymentGuard;
